export const isOrderStepExecuted = (
  step: uui.domain.client.rm.OrderStep,
  rescheduleBehavior: uui.domain.client.rm.Territory['rescheduleBehavior'],
): boolean => {
  const { status, timeInSec, timeOutSec, autoTimeInSec, autoTimeOutSec } = step.trackingData

  const hasDetected = autoTimeInSec > -1 || autoTimeOutSec > -1
  const hasReported = timeInSec > -1 || timeOutSec > -1
  const hasStatusReschueduled = !!status && status === 'reschedule'
  const hasStatusDone = !!status && status === 'done'

  switch (rescheduleBehavior) {
    case 'ON_REPORTED_FIRST':
    case 'ON_DETECTED_FIRST':
      return (hasReported || hasDetected || hasStatusDone) && !hasStatusReschueduled

    case 'ON_REPORTED_ONLY':
      return (hasReported || hasStatusDone) && !hasStatusReschueduled

    case 'ON_DETECTED_ONLY':
      return hasDetected

    default:
      return false
  }
}
